.footer {
  display: flex;
  flex-direction: column;
  padding: 60px 0;
  background: #222;
}

.footer .first {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  color: aliceblue;
}

.footer .first .logoName {
  font-size: 20px;
  margin-bottom:20px;
}

.footer .first .address {line-height:1.5;}

.footer .first .contact {
  text-align: right;
  font-size: 20px;
}

.footer .first .discord {
  display: flex;
  font-size: 30px;
  align-items: center;
  margin:10px 0;
}

.discord .discordIcon {
  padding-right: 5px;
  height: 25px;
}
.footer .first .email {
  text-align: right;
  font-size: 13px;
}

.footer hr {
  margin-top: 15px;
  margin-bottom: 15px;
}

.secondfooter {
  color: aliceblue;
}

@media (max-width: 1320px) {
  .footer .first {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .footer .first .contact {
    padding-top: 60px;
    text-align: left;
  }
  .footer .first .email {
    text-align: left;
  }
}