/* css reset start */
* {margin:0; padding:0; line-height:1; box-sizing:border-box; word-break:keep-all;}
body {font-size:12px; font-weight:normal;}
ul,li,ol {list-style:none;}
img,video {vertical-align:top;}
a {text-decoration:none; color:#000;}
table {border-collapse:collapse;}
input,select, textarea {outline:0;}
.center {width:90%; max-width:1200px; margin:0 auto;}
/* css reset end */

/* #main start */
#main .slideBox {width:100%; height:80vh; position:relative;}
#main .slideBox .swiper-button-prev {left:16%; top:auto; bottom:28.5%;}
#main .slideBox .swiper-button-next {left:25%; top:auto; bottom:28.5%;}
#main .slideBox .swiper-button-prev::after,
#main .slideBox .swiper-button-next::after {color:#fff; font-size:20px; font-weight:bold;}
#main .slideBox .swiper-pagination {width:fit-content; left:20%; bottom:30%; font-size:20px;
    color:#fff; font-weight:bold;
}
#main .slideBox .swiper-pagination .swiper-pagination-current {color:#2183C6;}
#main .slideBox .swiper-wrapper > .one {background:url(../assets/mainslide1.png) no-repeat center / cover;}
#main .slideBox .swiper-wrapper > .two {background:url(../assets/mainslide2.png) no-repeat center / cover;}
#main .slideBox .swiper-wrapper > .three {background:url(../assets/mainslide3.jpg) no-repeat center / cover;}
#main .slideBox .swiper-wrapper .swiper-slide .textWrap {position:absolute; left:16%; top:30%;}
#main .slideBox .swiper-wrapper .swiper-slide .textWrap h3 {color:#fff; font-size:40px; word-break:keep-all;
    line-height:1.2;
}
#main .slideBox .swiper-wrapper .swiper-slide .textWrap h3 span {color:#2183C6;}
#main .slideBox .swiper-wrapper .swiper-slide .textWrap p {color:#fff; font-size:24px; margin-top:30px;
    word-break:keep-all; line-height: 1.2;}

#main .valueBox {padding:100px 0;}
#main .valueBox .center > h2{color:#2183C6; font-size:40px; text-align:center;}
#main .valueBox .center > p{font-size:24px; margin-top:20px; text-align:center;}
#main .valueBox .center .valueList {width:100%; display:flex; align-items:center; 
    justify-content:space-between; margin-top:50px;
}
#main .valueBox .center .valueList > li {width:24%; height:400px; background-size:cover;
    background-repeat:no-repeat; position:relative; background-position:center;
    transition:all 0.5s; color:#fff; border-radius:20px; top:0; overflow:hidden;
}
#main .valueBox .center .valueList > li:nth-child(1) {background-image:url(../assets/waste.jpg);}
#main .valueBox .center .valueList > li:nth-child(2) {background-image:url(../assets/nature.jpg);}
#main .valueBox .center .valueList > li:nth-child(3) {background-image:url(../assets/safety.jpg);}
#main .valueBox .center .valueList > li:nth-child(4) {background-image:url(../assets/partnership.jpg);}
#main .valueBox .center .valueList > li:hover {top:-20px;}
#main .valueBox .center .valueList > li .bg {width:100%; height:100%; position:absolute;
    left:0; top:0; transition:all 0.5s;
}
#main .valueBox .center .valueList > li:hover .bg {background:#2183C6;}
#main .valueBox .center .valueList > li >.bg  p {color:transparent; margin:20px; font-size:20px;
    line-height:1.5; text-align:center; word-break: keep-all;
}
#main .valueBox .center .valueList > li:hover .bg > p {color:#fff;}
#main .valueBox .center .valueList > li > h3 {position:absolute; left:50%; bottom:50px;
    transform: translateX(-50%); font-size:20px; width:100%; text-align:center;
}
#main .valueBox .center .valueList > li:hover > h3::after {content:""; display:block;
    position:absolute; top:-20px; left:50%; transform:translateX(-50%); width:30px; height:2px;
    background:#fff;
}
#main .valueBox .center .valueSlide {width:90%; height:400px; margin-top:50px; border-radius:20px;}
#main .valueBox .center .valueSlide .swiper-button-next::after,
#main .valueBox .center .valueSlide .swiper-button-prev::after {color:#2183C6; font-weight:bold;}
#main .valueBox .center .valueSlide .swiper-wrapper .waste {background-image:url(../assets/waste.jpg);}
#main .valueBox .center .valueSlide .swiper-wrapper .nature {background-image:url(../assets/nature.jpg);}
#main .valueBox .center .valueSlide .swiper-wrapper .safe {background-image:url(../assets/safety.jpg);}
#main .valueBox .center .valueSlide .swiper-wrapper .partner {background-image:url(../assets/partnership.jpg);}
#main .valueBox .center .valueSlide .swiper-wrapper .swiper-slide {background-size:cover;
    background-repeat:no-repeat; background-position:center; transition:all 0.5s; position:relative;
}
#main .valueBox .center .valueSlide .swiper-wrapper .swiper-slide .bg {
    position:absolute; left:0; top:0; width:100%; height:100%; background:transparent;
    transition:all 0.5s;
}
#main .valueBox .center .valueSlide .swiper-wrapper .swiper-slide .bg p {display:none;
    margin:20px; font-size:20px; line-height:1.5; text-align:center; word-break:keep-all;
    -webkit-user-select:all; -moz-user-select:all; -ms-user-select:all; user-select:all
}
#main .valueBox .center .valueSlide .swiper-wrapper .swiper-slide:hover .bg {background:#2183C6;}
#main .valueBox .center .valueSlide .swiper-wrapper .swiper-slide:hover .bg p {display:block; color:#fff;}
#main .valueBox .center .valueSlide .swiper-wrapper .swiper-slide h3 {
    position:absolute; left:50%; bottom:50px; transform:translateX(-50%); font-size:20px;
    width:100%; text-align:center; color:#fff;
}
#main .valueBox .center .valueSlide .swiper-wrapper .swiper-slide h3::after {content:"";
    display:block; position:absolute; top:-20px; left:50%; transform:translateX(-50%);
    width:0px; height:2px; background-color:#fff; transition:all 0.5s;
}
#main .valueBox .center .valueSlide .swiper-wrapper .swiper-slide:hover h3::after {width:30px;}

#main .serviceBox .center > h2 {color:#2183C6; font-size:40px; text-align:center;}
#main .serviceBox .center > p {font-size:24px; margin-top:20px; text-align:center;}
#main .serviceBox .center .serviceList {width:100%; display:flex; align-items:center;
    justify-content:space-between;
}
#main .serviceBox .center .serviceList > li {width:18%; box-shadow:5px 5px 10px rgba(0,0,0,0.2);
    height:400px; border-radius:20px; position:relative; margin-top:50px; transition:all 0.5s; top:0;}
#main .serviceBox .center .serviceList > li:hover {top:-20px;}
#main .serviceBox .center .serviceList > li:nth-child(1) .img {width:100%; height:100%; background:url(../assets/icon-truck.png) no-repeat center / 60%;}
#main .serviceBox .center .serviceList > li:nth-child(2) .img {width:100%; height:100%; background:url(../assets/icon-ai.png) no-repeat center / 60%;}
#main .serviceBox .center .serviceList > li:nth-child(3) .img {width:100%; height:100%; background:url(../assets/icon-shopping.png) no-repeat center / 60%;}
#main .serviceBox .center .serviceList > li:nth-child(4) .img {width:100%; height:100%; background:url(../assets/icon-cart.png) no-repeat 30% center / 60%;}
#main .serviceBox .center .serviceList > li:nth-child(5) .img {width:100%; height:100%; background:url(../assets/icon-demotion.png) no-repeat center / 60%;}
#main .serviceBox .center .serviceList > li .bg {width:100%; height:100%; position:absolute;
    left:0; top:0; transition:all 0.5s; border-radius:20px;
}
#main .serviceBox .center .serviceList > li:hover .bg {background:#2183C6;}
#main .serviceBox .center .serviceList > li .bg > p {color:transparent; margin:20px; font-size:20px;
    line-height:1.5; text-align:center; word-break:keep-all;
}
#main .serviceBox .center .serviceList > li .bg > a {color:transparent; position:absolute; left:50%;
    bottom:110px; transform:translateX(-50%); font-size:16px; white-space:nowrap;
}
#main .serviceBox .center .serviceList > li:hover .bg > p {color:#fff;}
#main .serviceBox .center .serviceList > li:hover .bg > a {color:#fff;}
#main .serviceBox .center .serviceList > li > h3 {position:absolute; left:50%; bottom:50px;
    transform:translateX(-50%); font-size:20px; width:100%; text-align:center;
}
#main .serviceBox .center .serviceList > li:hover > h3 {color:#fff;}
#main .serviceBox .center .serviceList > li:hover > h3::after {content:""; display:block;
    position:absolute; top:-20px; left:50%; transform:translateX(-50%); width:30px; height:2px;
    background:#fff; z-index: 1;
}
#main .serviceBox .center .serviceList > li > p {position:absolute; left:-5px; top:20px;
    background:#ffcc00; padding:5px 10px; border-radius:5px;
}
#main .serviceBox .center .serviceSlide {width:90%; height:400px; margin-top:50px; position:relative;
    box-shadow: 5px 5px 10px rgb(0 0 0 / 20%); border-radius:20px;
}
#main .serviceBox .center .serviceSlide .swiper-wrapper .sell .img {width:40%; height:40%; 
    background:url(../assets/icon-truck.png) no-repeat center / contain;
    margin:60px auto 0;
}
#main .serviceBox .center .serviceSlide .swiper-wrapper .ai .img {width:40%; height:40%;
     background:url(../assets/icon-ai.png) no-repeat center / contain;
     margin:60px auto 0;
    }
#main .serviceBox .center .serviceSlide .swiper-wrapper .buy .img {width:40%; height:40%; 
    background:url(../assets/icon-shopping.png) no-repeat center / contain;
    margin:60px auto 0;
}
#main .serviceBox .center .serviceSlide .swiper-wrapper .shop .img {width:40%; height:40%; 
    background:url(../assets/icon-cart.png) no-repeat 30% center / contain;
    margin:60px auto 0;
}
#main .serviceBox .center .serviceSlide .swiper-wrapper .demotion .img {width:40%; height:40%; 
    background:url(../assets/icon-demotion.png) no-repeat center / contain;
    margin:60px auto 0;
}
#main .serviceBox .center .serviceSlide .swiper-wrapper .swiper-slide .bg {width:100%; height:100%; position:absolute;
    left:0; top:0; transition:all 0.5s; border-radius:20px;
}
#main .serviceBox .center .serviceSlide .swiper-wrapper .swiper-slide:hover .bg {background:#2183C6;}
#main .serviceBox .center .serviceSlide .swiper-wrapper .swiper-slide .bg > p {color:transparent; margin:20px; font-size:20px;
    line-height:1.5; text-align:center; word-break:keep-all;
}
#main .serviceBox .center .serviceSlide .swiper-wrapper .swiper-slide .bg > a {color:transparent; position:absolute; left:50%;
    bottom:110px; transform:translateX(-50%); font-size:16px;
}
#main .serviceBox .center .serviceSlide .swiper-wrapper .swiper-slide:hover .bg > p {color:#fff;}
#main .serviceBox .center .serviceSlide .swiper-wrapper .swiper-slide:hover .bg > a {color:#fff;}
#main .serviceBox .center .serviceSlide .swiper-wrapper .swiper-slide > h3 {position:absolute; left:50%; bottom:50px;
    transform:translateX(-50%); font-size:20px; width:100%; text-align:center;
}
#main .serviceBox .center .serviceSlide .swiper-wrapper .swiper-slide:hover > h3 {color:#fff;}
#main .serviceBox .center .serviceSlide .swiper-wrapper .swiper-slide:hover > h3 {color:#fff;}
#main .serviceBox .center .serviceSlide .swiper-wrapper .swiper-slide:hover > h3::after {content:""; display:block;
    position:absolute; top:-20px; left:50%; transform:translateX(-50%); width:30px; height:2px;
    background:#fff; z-index: 1;
}
#main .serviceBox .center video {width:100%; border-radius:10px; box-shadow:5px 5px 10px rgba(0,0,0,0.2); margin:50px 0;}


#main .partnershipBox {background:url(../assets/earth.jpg) no-repeat center / cover; padding:150px 0;}
#main .partnershipBox .center {display:flex; align-items:center; justify-content:space-between;}
#main .partnershipBox .center .leftBox {width:50%;}
#main .partnershipBox .center .leftBox h2 {font-size:24px; color:#fff; margin-bottom:100px; line-height:1.5;}
#main .partnershipBox .center .leftBox a {font-size:20px; color:#fff; font-weight:bold; transition:all 0.5s; position:absolute;}
#main .partnershipBox:hover .center .leftBox a {font-size:30px; color:#2183C6}
#main .partnershipBox .center .rightBox {width:50%; position:relative;}
#main .partnershipBox .center .rightBox > div {position:absolute;}
#main .partnershipBox .center .rightBox > div p {font-size:16px; color:#fff; margin-bottom:10px;}
#main .partnershipBox .center .rightBox > div h3 {font-size:24px; color:#fff; transition:all 0.5s;}
#main .partnershipBox .center .rightBox > div:hover h3 {font-size:40px;}
#main .partnershipBox .center .rightBox .member {left:50px; top:-115px;}
#main .partnershipBox .center .rightBox .visit {right:50px; top:-10px;}
#main .partnershipBox .center .rightBox .visit p {text-align:right;}
#main .partnershipBox .center .rightBox .partner {left:150px; top:50px;}

/* main end */

/* company start */
#company {overflow:hidden;}
/* 공용 타이틀 구간 */
#company .title {padding:150px 0 400px; position:relative; background-attachment:fixed;}
#company .title .center > h2 {font-size:60px; color:#fff; font-weight:bold;}
#company .title .center > p {font-size:20px; color:#fff; margin-top:30px;}
#company .title .center .companyNav {position:absolute; left:50%; bottom:0; transform:translateX(-50%); width:90%;
    max-width:1200px; background-color:#fff; display:flex; align-items:center; justify-content:space-between;
    padding:20px 0;
}
#company .title .center .companyNav li {width:15%; text-align:center; font-size:20px; position:relative; cursor: pointer;}
#company .title .center .companyNav li:nth-child(1) {width:35%; text-align:left; margin-left:5%;}
#company .title .center .companyNav li:nth-child(1)::before {content:""; width:10px; height:10px; display:block;
    background-color: #2183C6; border-radius:50%; position:absolute; left:-20px; top:50%;
    transform:translateY(-50%); 
}
#company .title .center .companyNav li a {position:relative;}
#company .title .center .companyNav li a::after {content:""; display:block; position:absolute; bottom:0;
    left:50%; transform:translateX(-50%); width:0%; height:50%; background-color:#2183C6; opacity:.2;
    z-index:-1; transition: all 0.5s;
}
#company .title .center .companyNav li:hover a::after {content:""; display:block; position:absolute; bottom:0;
    left:50%; transform:translateX(-50%); width:140%; height:50%; background-color:#2183C6; opacity:.2;
    z-index:-1;
}
#company .title .center .companyNav li.on a::after {content:""; display:block; position:absolute; bottom:0;
    left:50%; transform:translateX(-50%); width:140%; height:50%; background-color:#2183C6; opacity:.5;
    z-index:-1;
}
#company .companyBox .center > p {width:100%; font-size:16px; color:#ddd; text-align:center; margin:50px 0 10px;}
#company .companyBox .center > h3 {width:100%; font-size:24px; text-align:center; line-height:1.5; margin-bottom:50px;}

/* 소개 */
#company.company {padding-bottom:50px;}
#company.company .title {background:url(../assets/companymainimage.jpg) no-repeat center / cover;  background-attachment: fixed;}
#company .companyBox.introduce .center {display:flex; align-items:center; justify-content:space-between; flex-wrap:wrap;}
#company .companyBox.introduce .center .imgBox {width:40%; background:url(../assets/earth.jpg) no-repeat center / cover;
    border-radius:30px;
}
#company .companyBox.introduce .center .imgBox::after {content:""; padding-bottom:100%; display:block;}
#company .companyBox.introduce .center .textBox {width:55%;}
#company .companyBox.introduce .center .textBox h4 {font-size:40px; word-break:keep-all; line-height:1.2;}
#company .companyBox.introduce .center .textBox h4 span {color:#2183C6;}
#company .companyBox.introduce .center .textBox p {font-size:20px; line-height:1.2; margin-top:40px; word-break: keep-all;}

/* 연혁 */
#company.company .history .timeLine {position:relative; display:flex; flex-wrap:wrap;}
#company.company .history .timeLine::after {content:""; display:block; width:2px; height:100%;
  background:#2183C6; position:absolute; left:50%; top:0; transform:translateX(-50%);
}
#company.company .history .timeLine > li {width:48%; padding:30px 0; position:relative;}
#company.company .history .timeLine > li::after {content:""; display:block;
  width:20px; height:20px; background:#2183C6; border-radius:50%;
  position:absolute; left:-34px; top:63px;
  top:45px; /*2안*/
}
#company.company .history .timeLine > li:nth-child(even) {text-align:right; margin-right:5%;}
#company.company .history .timeLine > li:nth-child(even)::after {left:auto; right:-34px;}
#company.company .history .timeLine > li:nth-child(odd) {margin-left:52%;}
#company.company .history .timeLine > li h5 {font-size:20px;
  position:absolute; left:-130px; top:35px; background:#2183C6; color:#fff;
  border-radius:50px; padding:10px 20px;
}
#company.company .history .timeLine > li:nth-child(even) h5 {left:auto; right:-130px;}
#company.company .history .timeLine > li h4 {font-size:24px; color:#2183C6; margin:10px 0 20px;}
#company.company .history .timeLine > li > ul > li {line-height:1.5; font-size:16px;}

/* 수상 */
#company.company .award .center .awardWrap {margin:50px 0;}
#company.company .award .center .awardWrap h4 {font-size:40px; margin-bottom:30px;}
#company.company .award .center .awardWrap ul li {display:flex; align-items:flex-start;
  justify-content: space-between; margin-bottom:50px;
}
#company.company .award .center .awardWrap ul li img {width:30%; transition:all 0.3s;}
#company.company .award .center .awardWrap ul li .textWrap {width:60%;}
#company.company .award .center .awardWrap ul li .textWrap time {font-size:20px;}
#company.company .award .center .awardWrap ul li .textWrap h5 {font-size:30px; color:#2183C6;
  margin:20px 0;
}
#company.company .award .center .awardWrap ul li .textWrap p {font-size:20px;}

/* 제휴소개 */
/* 파트너스 */
#company.partnership .title {background:url(../assets/partnershipmainimage.jpg) no-repeat center / cover; background-attachment: fixed;}
#company .partnershipBox .center .partners {margin:50px 0;}
#company .partnershipBox .center .partners > h4 {font-size:24px; text-align:center;}
#company .partnershipBox .center .partners ul {display:flex; align-items:center;
  justify-content:space-between; margin-top:40px;
}
#company .partnershipBox .center .partners ul li {width:32%; text-align:center;}
#company .partnershipBox .center .partners ul li h5 {width:fit-content; color:#fff; background:#2183C6;
  font-size:20px; padding:10px 20px; margin:0 auto; position:relative;
}
#company .partnershipBox .center .partners ul li h5::after {
  border-top:10px solid #2183C6; border-left:10px solid transparent;
  border-right:10px solid transparent; border-bottom:0px solid transparent;
  content:""; position:absolute; top:40px; left:50%; transform:translateX(-50%);
}
#company .partnershipBox .center .partners ul li .imgWrap {
  width:70%; border:10px solid #2183C6; border-radius:50%;
  position:relative; margin:20px auto;
}
#company .partnershipBox .center .partners ul li .imgWrap::after {
  content:""; display:block; width:100%; padding-bottom:100%;
}
#company .partnershipBox .center .partners ul li .imgWrap img {
  position:absolute; left:50%; top:50%; transform:translate(-50%,-50%);
  width:50%; min-width:100px;
}
#company .partnershipBox .center .partners ul li p {font-size:14px;
  line-height:1.2; word-break:keep-all;
}
/* 띠배너 */
#company .partnershipBox .center .tiebanner {width:100vw; padding:100px 0;
  margin-left:calc(-50vw + 50%); background-attachment: fixed;
  background:url(../assets/servicetiebanner2.jpg) no-repeat center / cover;
}
#company .partnershipBox .center .tiebanner .bg {width:100%; height:100%;
  position:absolute; left:0; top:0; background:rgba(0,0,0,.5);
}
#company .partnershipBox .center .tiebanner h4 {font-size:24px; color:#fff;
  text-align:center; position:relative; z-index: 1;
}
#company .partnershipBox .center .tiebanner p {font-size:18px; color:#fff;
  text-align:center; margin-top:20px; position:relative; z-index: 1;
}
/* 서비스 */
#company.service .title {background:url(../assets/servicemainimage.jpg) no-repeat center / cover; background-attachment: fixed;}
#company .partnershipBox .center .service {margin:50px 0; display:flex;
  align-items:center; justify-content:space-between; flex-wrap:wrap;
}
#company .partnershipBox .center .service h4 {width:100%;font-size:24px;
  margin-bottom:20px; text-align:center; margin-bottom:40px;}
#company .partnershipBox .center .service ul {width:50%;}
#company .partnershipBox .center .service ul li {margin-bottom:40px;}
#company .partnershipBox .center .service ul li h5 {font-size:24px;
  margin-bottom:10px; position:relative; color:#2183C6
}
#company .partnershipBox .center .service ul li h5::before {content:"";
  display:block; background-position:center; background-repeat:no-repeat;
  background-size:cover;
  width:30px; height:30px;
  position:absolute; left:-30px; top:-10px;
}
#company .partnershipBox .center .service ul li:nth-child(1) h5::before {
  background-image: url(../assets/onef.png);
}
#company .partnershipBox .center .service ul li:nth-child(2) h5::before {
  background-image: url(../assets/twop.png);
}
#company .partnershipBox .center .service ul li:nth-child(3) h5::before {
  background-image: url(../assets/threep.png);
}
#company .partnershipBox .center .service ul li:nth-child(4) h5::before {
  background-image: url(../assets/fourp.png);
}
#company .partnershipBox .center .service ul li p {
  font-size:16px; word-break:keep-all; width:80%; line-height:1.2;
}
#company .partnershipBox .center .service > img {width:50%;}
/* 가입안내 */
#company .partnershipBox .center .enjoy {background: #084D7D; width:100vw;
  margin-left:calc(-50vw + 50%); text-align:center; padding:50px 0; margin-top:100px;
}
#company .partnershipBox .center .enjoy h4 {font-size:24px; color:#fff;
  word-break:keep-all; width:90%; margin:0 auto; line-height:1.5;
}
#company .partnershipBox .center .enjoy ul {margin:20px 0;}
#company .partnershipBox .center .enjoy ul li {width:90%; max-width:900px; margin:80px auto;
  border:1px solid #fff; padding:20px 0; border-radius:10px; font-size:24px; color:#fff;
  position:relative;
}
#company .partnershipBox .center .enjoy ul li:nth-child(1)::before {
  content:"가입절차"; display:block; font-weight:bold; color:#ffe600; position:absolute;
  left:50%; top:-50px; transform:translateX(-50%); font-size:20px;
}
#company .partnershipBox .center .enjoy ul li:nth-child(1)::after {
  content:"1";
}
#company .partnershipBox .center .enjoy ul li:nth-child(2)::after {
  content:"2";
}
#company .partnershipBox .center .enjoy ul li:nth-child(3)::after {
  content:"3";
}
#company .partnershipBox .center .enjoy ul li::after { display:block; position:absolute;
  left:50%; top:-15px; transform:translateX(-50%); font-size:16px; border-radius:50%;
  background:#ffe600; width:30px; height:30px; line-height:30px; color:#084D7D;
}
#company .partnershipBox .center .enjoy ul li:nth-child(2)::before,
#company .partnershipBox .center .enjoy ul li:nth-child(3)::before {
  content:""; display:block; position:absolute; left:50%; top:-60px; transform:translateX(-50%);
  background:url(../assets/arrowDown.png) no-repeat center / cover; width:30px;height:30px;
}
#company .partnershipBox .center .enjoy ul li span {display:block;
  line-height:1.5; font-size:14px; word-break: keep-all; width:80%; margin:20px auto 0;
}
#company .partnershipBox .center .enjoy > a {width:90%; max-width:900px; margin:0 auto;
  border:1px solid #fff; padding:20px 0; border-radius:10px; font-size:20px; color:#fff;
  display:block; transition:all 0.5s;
}
#company .partnershipBox .center .enjoy > a:hover {background:#fff; color:#084D7D;
  box-shadow:0px 0px 10px rgba(255,255,255,0.5);
}

/* about start */
#company #about1 {width:90%; max-width:1200px; margin:100px auto; display:flex; align-items:flex-start;
  justify-content:space-between; flex-direction: row-reverse; position:relative;
}
#company #about1 .text {position:relative; width:40%;}
#company #about1 .text h3 {color:#2183C6; font-size:40px;}
#company #about1 .text h4 {font-size:30px; line-height:1.2; margin:20px 0 40px;}
#company #about1 .text p {font-size:24px; line-height:1.5; font-weight:normal; word-break:keep-all;}
#company #about1 .text a {position:absolute; right:0; top:24px; color:#2183C6; font-size:16px;}
#company #about1 .phoneWrap {width:50%;}
#company #about1 .phoneWrap .phone {position:relative;}
#company #about1 .phoneWrap .phone:nth-child(3) {position:absolute; right:0; top:500px; width:50%;}
#company #about1 .phoneWrap .phone img {width:100%;}
#company #about1 .phoneWrap .phone video {position:absolute; width:83%; left:50%; top:52%;
  transform:translate(-50%,-50%); z-index: -1;
}
#company #about1 .phoneWrap .text {width:70%;}

#company #about2 {margin:400px auto; text-align:center; overflow:hidden; width:100%;}
#company #about2 h3 {font-size:40px; color:#2183C6; margin-bottom:20px;}
#company #about2 h4 {font-size:30px; line-height:1.2; margin:20px 0 40px;}
#company #about2 a {font-size:16px; color:#2183C6;}
#company #about2 img {width:100%;}

#company #about4 {width:90%; max-width:1200px; margin:400px auto 100px; display:flex; position:relative;
  align-items:flex-start; justify-content:space-between; flex-direction:row-reverse;
}
#company #about4 .text {position:relative; width:40%; }
#company #about4 .text {position:relative; width:40%; position:relative;}
#company #about4 .text h3 {color:#2183C6; font-size:40px;;}
#company #about4 .text h4 {font-size:30px; line-height:1.2; margin:20px 0 40px;}
#company #about4 .text p {font-size:24px; line-height:1.5; font-weight:normal; word-break:keep-all;} 
#company #about4 .text a {position:absolute; right:0; top:24px; color:#2183C6; font-size:16px;}
#company #about4 .phoneWrap {width:50%;}
#company #about4 .phoneWrap .phone {position:relative;}
#company #about4 .phoneWrap .phone img {width:100%;}
#company #about4 .phoneWrap .phone video {width:84%; position:absolute; left:50%; top:52%;
  transform: translate(-50%,-50%); z-index: -1;
}

#company #about5 {width:90%; max-width:1200px; margin:400px auto 100px;}
#company #about5 h3 {font-size:40px; color:#2183C6; margin-bottom:30px; text-align:center;}
#company #about5 video {width:100%;}

@media screen and (max-width:1200px) {
  /* main start */
  #main .slideBox .swiper-button-prev,
  #main .slideBox .swiper-button-next {display:none;}
  #main .slideBox .swiper-pagination {left:5%; bottom:5%;}
  #main .slideBox .swiper-wrapper .swiper-slide .textWrap {left:5%;}
  #main .partnershipBox {padding:100px 0 50px;}
  #main .partnershipBox .center {display:block;}
  #main .partnershipBox .center .leftBox,
  #main .partnershipBox .center .rightBox {width:100%;}
  #main .partnershipBox .center .leftBox h2 {font-size:20px; margin-bottom:20px;}
  #main .partnershipBox .center .leftBox a {font-size:16px; position:static;}
  #main .partnershipBox:hover .center .leftBox a {font-size:16px; color:#fff;}
  #main .partnershipBox .center .rightBox {margin-top:100px;}
  #main .partnershipBox .center .rightBox div {position:static; pointer-events: none;}
  #main .partnershipBox .center .rightBox .visit h3 {text-align:right;}
  /* main end */

  /* company start */
  /* 공용 타이틀 구간 */
  #company .title {padding:100px 0 150px;}
  #company .title .center > h2 {font-size:40px; word-break:keep-all;}
  #company .title .center .companyNav li {font-size:16px;}
  #company .title .center .companyNav li:nth-child(1) {width:35%; text-align:left; margin-left:5%; font-weight:bold;}
  #company .title .center .companyNav li:nth-child(1)::before {left:-12px;}

  #company .companyBox .center > p {font-size:14px;}
  #company .companyBox .center > h3 {font-size:20px; word-break:keep-all;}
  #company .companyBox .center > h3 p {display:inline;}
  
  /* about */
  #company #about1 {flex-wrap:wrap;}
  #company #about1 .text {width:100%; text-align:center;}
  #company #about1 .text h4 {font-size:20px; margin:20px 0; word-break:keep-all;}
  #company #about1 .text h4 br {display:none;}
  #company #about1 .text p {font-size:16px; margin-bottom:20px;}
  #company #about1 .text a {position:static; font-weight:bold;}
  #company #about1 .phoneWrap {width:80%; margin:50px auto 0;}

  #company #about2 h4 {font-size:20px; line-height:1.2; margin:20px 0 40px;}

  #company #about4 {display:block; margin-top:100px;}
  #company #about4 .text {width:100%; text-align:center;}
  #company #about4 .text h4 {font-size:20px; margin:20px 0; word-break:keep-all;}
  #company #about4 .text h4 br {display:none;}
  #company #about4 .text p {font-size:16px; margin-bottom:20px;} 
  #company #about4 .text a {position:static; font-weight:bold;}
  #company #about4 .phoneWrap {width:80%; margin:50px auto 0;}

  /* 연혁 */
  #company.company .history .timeLine::after {left:0; transform:translateX(0);}
  #company.company .history .timeLine > li {width:100%; margin-left:0; padding-left:30px;}
  #company.company .history .timeLine > li::after {left:-10px; top:83px;}
  #company.company .history .timeLine > li:nth-child(even) {text-align:left; margin-right:0%;}
  #company.company .history .timeLine > li:nth-child(even)::after {left:-10px; right:auto;}
  #company.company .history .timeLine > li:nth-child(odd) {margin-left:0;}
  #company.company .history .timeLine > li h5 {position:static; width:fit-content;}
  /* company end */
}
@media screen and (max-width:900px) {
  /* company start */
  /* 공용 */
  #company .title .center .companyNav li {font-size:12px;}
  /* 소개 */
  #company .companyBox.introduce .center {display:block;}
  #company .companyBox.introduce .center .imgBox {width:100%;}
  #company .companyBox.introduce .center .textBox {width:100%;}
  #company .companyBox.introduce .center .textBox h4 {font-size:24px; text-align:center; margin-top: 40px;}
  #company .companyBox.introduce .center .textBox h4 span {color:#2183C6;}
  #company .companyBox.introduce .center .textBox p {font-size:16px; line-height:1.5; margin-top:20px; word-break: keep-all; text-align:center;}

  /* 수상 */
  #company.company .award .center .awardWrap ul li img {width:38%;}

  #company #about2 {margin:200px auto;}
  /* 제휴소개 */
  /* 파트너스 */
  #company .partnershipBox .center .partners > h4 {font-size:20px; word-break:keep-all;}
  #company .partnershipBox .center .partners ul {display:block;}
  #company .partnershipBox .center .partners ul li {width:100%; margin-bottom:20px;}
  /* 띠배너 */
  #company .partnershipBox .center .tiebanner {margin-left:calc(-50vw + 48.1%);}
  #company .partnershipBox .center .tiebanner h4 {font-size:24px;}
  #company .partnershipBox .center .tiebanner p {font-size:12px;}
  /* 서비스 */
  #company.service .title .center .companyNav li {font-size:10px;}
  #company .partnershipBox .center .service {display:block;}
  #company .partnershipBox .center .service h4 {font-size:20px; word-break: keep-all;}
  #company .partnershipBox .center .service ul {width:100%;}
  #company .partnershipBox .center .service ul li {text-align:center;}
  #company .partnershipBox .center .service ul li h5 {font-size:20px;
    margin-bottom:10px; position:relative; color:#2183C6
  }
  #company .partnershipBox .center .service ul li h5::before {position:static; margin:0 auto;}
  #company .partnershipBox .center .service ul li p {width:100%;}
  #company .partnershipBox .center .service > img {display:none;}
  /* 가입안내 */
}